import cargo_loading from '../../assets/images/cargo_plane_loading.png';
import ocean_Freight from '../../assets/images/Ocean_Freight.jpg';
import plane_1 from '../../assets/images/plane_1.png';
import truck_2 from '../../assets/images/truck_2.jpg';
import truck_3 from '../../assets/images/truck_3.jpeg';
import truck_4 from '../../assets/images/truck_4.jpg';
import warehouse_1 from '../../assets/images/warehouse_1.jpg';
import warehouse_2 from '../../assets/images/warehouse_2.jpg';
import trucking from '../../assets/images/trucking-company-fleet.jpg';
import cargoloading2 from '../../assets/images/cargo_loading_2.jpeg';
import plane_flying_1 from '../../assets/images/plane_flying_1.jpeg';
import plane_flying_2 from '../../assets/images/plane_flying_2.jpeg';

export const IMAGES = {
    cargo_loading,
    ocean_Freight,
    plane_1,
    truck_2,
    truck_3,
    truck_4,
    warehouse_1,
    warehouse_2,
    trucking,
    cargoloading2,
    plane_flying_1,
    plane_flying_2
}