import './App.css';
import Home from './pages/home/Home';
import { ROUTE_PATHS } from "./constants/routes/routes";

import {
  createBrowserRouter,
} from "react-router-dom";
import Services from './pages/services';
import About from './pages/about/About';
import Tracking from './pages/tracking/Tracking';

export const routes = createBrowserRouter([
  {
    path: ROUTE_PATHS.PUBLIC.HOME,
    element: <Home />
  },
  {
    path: ROUTE_PATHS.PUBLIC.SERVICES,
    element: <Services />
  },
  {
    path: ROUTE_PATHS.PUBLIC.TRACKING,
    element: <Tracking />
  },
  {
    path: ROUTE_PATHS.PUBLIC.ABOUT,
    element: <About />
  }
])