import React, { useState } from 'react';
import { COURSEL } from '../../../constants/data/coursel';
import { motion, AnimatePresence } from "framer-motion"
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import classes from './styles.module.css';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../constants/routes/routes';

function Coursel() {
    const [selected, setSelected] = useState(1);
    const [slideRight, setSlideRight] = useState(true)

    const handleClickForward = () => {
        const arrLength = COURSEL.length
        const newNum = selected + 1;
        if(newNum > arrLength){
            setSelected(1)
        }else{
            setSelected(newNum)
        }
        setSlideRight(true)
    };

    const handleClickBackward = () => {
        const newNum = selected - 1;
        if(newNum === 0){
            setSelected(3)
        }else{
            setSelected(newNum)
        }
        setSlideRight(false)
    };

    return (
        <div className='h-[500px] w-full bg-overlay-80 flex overflow-hidden relative'>
            {COURSEL.map((i, idx) => (
                <AnimatePresence initial={false}>
                    {idx + 1 === selected && (
                        <motion.div
                            key="modal"
                            initial={{ x: slideRight ? 900 : -900, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            // exit={{ x: -900, opacity: 0 }}
                            transition={{ ease: "easeOut", duration: 0.5 }}
                            className='w-full'
                        >
                            <div className='w-full h-full bg-no-repeat bg-center bg-cover text-white' style={{backgroundImage: `url(${i.img})`}}>
                                <div className='w-full h-full justify-center flex flex-col bg-overlay-40 p-[20px] lg:p-[70px] '>
                                    <h1 className={`${classes.courselTitle} text-3xl font-bold`}>{i.title}</h1>
                                    <p className={`${classes.courselTag} font-medium text-lg mt-2 max-w-[800px]`}>{i.tag}</p>
                                    <Link to={ROUTE_PATHS.PUBLIC.TRACKING}><button className={`${classes.courselButton} px-7 py-3 bg-black text-white rounded-full font-medium text-lg w-fit mt-5`}>Track Package</button></Link>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            ))}
            <div className='w-fit h-full px-4 absolute top-0 left-0 flex items-center'>
                <div onClick={() => handleClickBackward()} className='w-[40px] h-[40px] bg-[#f5f5f5] flex items-center justify-center cursor-pointer text-2xl rounded-full'> <FiArrowLeft /> </div>
            </div>
            <div className='w-fit h-full px-4 absolute top-0 right-0 flex items-center'>
                <div onClick={() => handleClickForward()} className='w-[40px] h-[40px] bg-[#f5f5f5] flex items-center justify-center cursor-pointer text-2xl rounded-full'> <FiArrowRight /> </div>
            </div>
        </div>
    )
}

export default Coursel