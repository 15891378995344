import cargo_plane from '../../assets/images/plane_1.png';
import ocean_freight from '../../assets/images/Ocean_Freight.jpg';
import truck from '../../assets/images/truck_4.jpg';

export const COURSEL = [
    {
        img: cargo_plane,
        title: 'RELIABLE LOGISTICS SOLUTIONS FOR YOUR BUSINESS NEEDS',
        tag: 'Our Commitment to Providing Reliable Logistics Solutions Tailored to Your Unique Business Needs Is Unwavering. From Comprehensive Supply Chain Management to Precision Delivery Services, We Empower Your Business to Thrive and Flourish, So You Can Focus Your Resources Where They Matter Most.'
    },
    {
        img: truck,
        title: 'YOUR PARTNER IN WORLDWIDE CARGO TRANSPORTATION',
        tag: "From the Moment We Load Your Cargo to Its Safe and Secure Unloading, We Hold Ourselves to the Highest Standards of Safety, Efficiency, and Reliability in Worldwide Cargo Transportation. Our Commitment Leaves Nothing to Chance, Ensuring That Your Cargo Reaches Its Destination with Precision and Peace of Mind."
    },
    {
        img: ocean_freight,
        title: 'EXPERTISE AND INNOVATION IN LOGISTICS MANAGEMENT',
        tag: "With a Rich Heritage of Logistics Expertise and a Dedication to Innovation, We're Not Just Solving Logistics Challenges — We're Anticipating Them. Our Depth of Experience Combined with Cutting-Edge Solutions Enables Us to Steer Your Business Toward Unprecedented Success in the Ever-Evolving World of Logistics."
    }
]