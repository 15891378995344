import React, { useEffect } from 'react'
import Header from '../../components/common/Header/Header';
import { FiArrowDown } from 'react-icons/fi';
import { FaMoneyBill } from 'react-icons/fa';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { BsBoxes, BsFillLightningFill } from 'react-icons/bs';
import Footer from '../../components/common/footer/Footer';
import { SERVICES_DATA } from '../../constants/services/services';
import { IMAGES } from '../../constants/images/images';

const imgLink = 'https://media.wired.com/photos/590951f9d8c8646f38eef333/16:9/w_929,h_523,c_limit/walmart-advanced-vehicle-experience-wave-concept-truck.jpg';

function Services() {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);

    const handleScroll = () => {
        const targetSection = document.getElementById("main-section"); // Change to the ID of your target section

        if (targetSection) {
            targetSection.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }

    const Time = ({details, tag}) => {
        if(details === 'fast')
        return(
            <div className='flex items-center gap-2'>
                <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#f5f5f5] text-[#F28500]'> <BsFillLightningFill /> </div>
                <p className='font-medium text-[#5B5B5B]'>{tag}</p>
            </div>
        )

        if(details === 'large')
        return(
            <div className='flex items-center gap-2'>
                <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#f5f5f5] text-[#F28500]'> <BsBoxes /> </div>
                <p className='font-medium text-[#5B5B5B]'>{tag}</p>
            </div>
        )

        if(details === 'secure')
        return(
            <div className='flex items-center gap-2'>
                <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#f5f5f5] text-[#F28500]'> <AiFillSafetyCertificate /> </div>
                <p className='font-medium text-[#5B5B5B]'>{tag}</p>
            </div>
        )

        return(
            <div className='flex items-center gap-2'>
                <div className='h-[40px] w-[40px] rounded-full flex items-center justify-center bg-[#f5f5f5] text-[#F28500]'> <FaMoneyBill /> </div>
                <p className='font-medium text-[#5B5B5B] font-medium'>{tag}</p>
            </div>
        )
    }
    
    return (
        <div>
            <Header />
            <div className='max-w-[1240px] mx-auto px-[20px]'>
                <div className='overflow-hidden my-10 h-[400px] rounded-[20px] bg-[#f5f5f5] mt-10 object-cover bg-no-repeat bg-center bg-cover' style={{backgroundImage: `url(${IMAGES.plane_flying_2})`}}>
                    <div className='h-full w-full bg-overlay-50 p-10 flex flex-col justify-center text-white'>
                        <h1 className='font-bold text-3xl'>Services</h1>
                        <p className='font-medium max-w-[750px] mt-3'>Here at Rapid Pulse Logistics we're focused on providing our clients with tailored services meant to fit their requirements</p>
                        <button className='flex items-center h-[45px] w-fit px-7 bg-bel-red rounded-full font-semibold gap-2 text-sm mt-7' onClick={handleScroll}>Browse Services <FiArrowDown size={17} /> </button>
                    </div>
                </div>
                <div id='main-section'>
                {SERVICES_DATA.map((i, idx) => {
                    if(idx % 2 === 0)
                    return(
                        <div className='w-full py-10 flex flex-col gap-5 md:grid grid-cols-2 md:gap-20' key={idx}>
                            <div>
                                <h1 className='text-2xl font-bold text-bel-red'>{i.title}</h1>
                                <p className='text-base mt-3 opacity-70'>{i.details}</p>
                                <div className='mt-5'>
                                    <Time details={i.icon} tag={i.tag} />
                                </div>
                            </div>
                            <img className='min-h-[300px] w-full object-cover rounded-[20px]' src={i.img} />
                        </div>
                    )

                    return(
                        <div className='w-full py-10 flex flex-col-reverse gap-5 md:grid grid-cols-2 md:gap-20' key={idx}>
                            <img className='min-h-[300px] w-full object-cover rounded-[20px]' src={i.img} />
                            <div>
                                <h1 className='text-2xl font-bold text-bel-red'>{i.title}</h1>
                                <p className='text-base mt-3 opacity-70'>{i.details}</p>
                                <div className='mt-5'>
                                    <Time details={i.icon} tag={i.tag} />
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>
                
            </div>
            <Footer />
        </div>
    )
}

export default Services